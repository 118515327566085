import TenantManualBillDetails from './tenantManualBillDetails';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import advanceSearchModal from './advanceSearch';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'TenantManualBill',
  components: {
    TenantManualBillDetails,
    advanceSearchModal
  },
  watch: {
    currentPage: function () {
      this.getTenantManualBillList();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getTenantManualBillList();
    },
    selectAllCheckBox: function () {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      loader: false,
      payload: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: null,
      unsubscribe: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      depVsetParam: null,
      showAdvanceSearchModal: false,
      showTenantManualBillDetailsModal: false,
      unitName: null,
      unitDisplay: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      status: {
        value: null,
        text: null
      },
      searchParams: null,
      leaseUnitRowDetails: null,
      gridData: [],
      gridFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'bill_no'
        },
        {
          key: 'bill_status_vset_meaning',
          label: 'Bill Status'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'value_date'
        },
        {
          key: 'due_date'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'billing_period_start_date',
          label: 'Billing From Date'
        },
        {
          key: 'billing_period_end_date',
          label: 'Billing End Date'
        },
        {
          key: 'lease_prj_name'
        },
        {
          key: 'lease_tower_name'
        },
        {
          key: 'lease_floor_name'
        },
        {
          key: 'lease_prj_unit_name'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'customer_id'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_id'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'customer_site_address'
        },
        {
          key: 'first_party_gstn',
          label: 'First Party GSTN'
        },
        {
          key: 'third_party_gstn',
          label: 'Third Party GSTN'
        },
        {
          key: 'debtor_account_meaning',
          label: 'Debtor Account'
        },
        {
          key: 'request_id',
          label: 'Request Num'
        },
        {
          key: 'error_msg'
        }
      ],
      billHdrId: null,
      modalName: null,
      selectAllCheckBox: false,
      defaultValue: {
        text: null,
        value: null
      }
    };
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    },
    operatingUnit: {
      text: {
        required
      }
    },
    source: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.billHdrId = null;
          this.showTenantManualBillDetailsModal = true;
        }
        if (actionName === 'save' && !this.showTenantManualBillDetailsModal) {
          this.processTenantManualBill();
        }
      }
    });
  },
  methods: {
    onDeletion() {
      setTimeout(() => {
        this.showTenantManualBillDetailsModal = false;
        this.getTenantManualBillList();
      }, 1000);
    },
    mainSearch() {
      this.searchParams = null;
      this.getTenantManualBillList();
    },
    getTenantManualBillList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loader = true;
        this.payload = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          }
        };
        if (!this.searchParams) {
          this.payload.searchParams = {
            le_id: this.legalEntity.value,
            ou_id: this.operatingUnit.value,
            source_hdr_id: this.source.value,
            bill_status_vset: this.status.value
          };
        } else {
          this.payload.searchParams = this.searchParams;
        }
        this.$store
          .dispatch('leaseInvoice/getTenantManualBillList', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.gridData = resp.data.data.page.map(data => {
                data.selectBox = false;
                return data;
              });
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    processTenantManualBill() {
      const selectedRows = this.gridData.filter(data => data.selectBox);
      if (selectedRows && selectedRows.length > 0) {
        const prepareData = selectedRows.map(data => {
          return {
            id: data.bill_hdr_id
          };
        });
        const payload = {
          bill_hdr_id: { bill_id: prepareData },
          ou_id: this.operatingUnit.value
        };
        this.loader = true;
        this.$store
          .dispatch('leaseInvoice/processTenantManualBill', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.getTenantManualBillList();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert('Please select atlease one data.');
      }
    },
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = null;
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        this.parent_value_set_id = this.operatingUnit.value;
        this.depVsetParam = this.legalEntity.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = this.defaultValue;
        this.source = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.source = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        this.source = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET) {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
        this.source = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = this.defaultValue;
        this.source = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        this.source = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET) {
        this.status = this.defaultValue;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.billHdrId = item.bill_hdr_id;
      this.showTenantManualBillDetailsModal = true;
    },
    showHideAdvSearchModal(flag) {
      this.showAdvanceSearchModal = flag;
    },
    advanceSearch(params) {
      this.legalEntity.text = params.le_name;
      this.legalEntity.value = params.searchParams.le_id;
      this.operatingUnit.text = params.ou_name;
      this.operatingUnit.value = params.searchParams.ou_id;
      this.source.text = params.source_name;
      this.source.value = params.searchParams.source_hdr_id;
      this.searchParams = params.searchParams;
      this.getTenantManualBillList();
      this.showAdvanceSearchModal = false;
    },
    closeTenantManualBillDetailModal(flag, modalName) {
      this.showTenantManualBillDetailsModal = flag;
      this.modalName = modalName;
    },
    selectBoxChecked(flag, index) {
      this.gridData[index].selectBox = flag;
    },
    checkUncheckAll() {
      this.gridData = this.gridData.map(data => {
        if (data.request_id) {
          if (!/e/i.test(data.bill_status_vset)) {
            data.selectBox = false;
          } else {
            data.selectBox = this.selectAllCheckBox;
          }
        } else {
          data.selectBox = this.selectAllCheckBox;
        }
        return data;
      });
    },
    validateCheckBox(row) {
      if (row.request_id) {
        if (!/e/i.test(row.bill_status_vset)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    clearSearchFilters() {
      this.gridData = [];
      this.currentPage = 1;
      this.totalRows = null;
      this.legalEntity = {
        value: null,
        text: null
      };
      this.operatingUnit = {
        value: null,
        text: null
      };
      this.source = {
        value: null,
        text: null
      };
      this.status = {
        value: null,
        text: null
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
