import RequestLease from '../../../../../request/submittedRequest';
import commonHelper from '@/app/utility/common.helper.utility';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import Party from '../../../../../generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import Currency from '../../../../../admin/currency';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'AdvanceSearch',
  components: {
    RequestLease,
    BasicSelect,
    ModelSelect,
    DatePicker,
    Currency,
    Party
  },
  data() {
    return {
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      showCustomerSiteModal: false,
      showMasterLeaseAgreementList: false,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      selectedBillingStatus: {
        value: null,
        text: null
      },
      billNo: null,
      dueDate: [],
      glDate: [],
      prjName: {
        value: null,
        text: null
      },
      towerName: {
        value: null,
        text: null
      },
      floorName: {
        value: null,
        text: null
      },
      unitName: {
        value: null,
        text: null
      },
      currency: {
        name: null,
        code: null,
        id: null
      },
      request: {
        value: null,
        text: null
      },
      selectedCustomer: {
        name: null,
        id: null,
        siteName: null,
        siteId: null
      },
      customerId: null,
      customer_id: null,
      customer_name: null,
      customer_site_id: null,
      customer_site_name: null,
      startDate: null,
      endDate: null,
      start_date_end_date: [],
      amtFrom: null,
      amtTo: null,
      vsetCode: null,
      setTimeVsetCode: null,
      showCommonModal: false,
      openCurrencyModal: false,
      modalType: null,
      depVsetParam: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('getCurrency', getCurrency => {
      this.currency.name = getCurrency.currency;
      this.currency.code = getCurrency.currency_code;
      this.currency.id = getCurrency.currency_id;
      this.openCurrencyModal = false;
    });
  },
  methods: {
    searchAdvParams() {
      const payload = {
        le_name: this.legalEntity.text,
        ou_name: this.operatingUnit.text,
        source_name: this.source.text,
        searchParams: {
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value,
          source_hdr_id: this.source.value,
          bill_no: this.billNo,
          due_date_from: commonHelper.formattedDate(this.dueDate[0]),
          due_date_to: commonHelper.formattedDate(this.dueDate[1]),
          gl_date_from: commonHelper.formattedDate(this.glDate[0]),
          gl_date_to: commonHelper.formattedDate(this.glDate[1]),
          billing_period_start_date: commonHelper.formattedDate(
            this.start_date_end_date[0]
          ),
          billing_period_end_date: commonHelper.formattedDate(
            this.start_date_end_date[1]
          ),
          customer_id: this.selectedCustomer.id,
          customer_site_id: this.selectedCustomer.siteId,
          total_amount_from: this.amtFrom,
          total_amount_to: this.amtTo,
          bill_status_vset: this.selectedBillingStatus.value,
          lease_prj_id: this.prjName.value,
          tower_id: this.towerName.value,
          floor_id: this.floorName.value,
          lease_prj_unit_id: this.unitName.value,
          transactional_currency_id: this.currency.id,
          request_id: this.request.value,
          trx_type_dtl_id: 8
        }
      };
      this.$emit('advanceSearch', payload);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.OU_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.prjName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.parent_value_set_id = this.towerName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.parent_value_set_id = this.floorName.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        this.parent_value_set_id = this.operatingUnit.value;
        this.depVsetParam = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit = { value: null, text: null };
        this.source = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
        this.source = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        this.source = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.prjName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.towerName.text = null;
        this.towerName.value = null;
        this.floorName.text = null;
        this.floorName.value = null;
        this.unitName.text = null;
        this.unitName.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.towerName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.floorName.text = null;
        this.floorName.value = null;
        this.unitName.text = null;
        this.unitName.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.floorName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.unitName.text = null;
        this.unitName.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.unitName.text = item.value_code;
        this.unitName.value = item.value_set_dtl_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET
      ) {
        this.selectedBillingStatus.text = item.value_meaning;
        this.selectedBillingStatus.value = item.value_code;
      }
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = { value: null, text: null };
          this.operatingUnit = { value: null, text: null };
          this.source = { value: null, text: null };
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          this.operatingUnit = { value: null, text: null };
          this.source = { value: null, text: null };
          break;
        case appStrings.VALUESETTYPE.SOURCE_TYPE:
          this.source = { value: null, text: null };
          break;
        case appStrings.VALUESETTYPE.LEASE_PROJECT_LIST:
          this.prjName = { value: null, text: null };
          this.towerName = { value: null, text: null };
          this.floorName = { value: null, text: null };
          this.unitName = { value: null, text: null };
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
          this.towerName = { value: null, text: null };
          this.floorName = { value: null, text: null };
          this.unitName = { value: null, text: null };
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          this.floorName = { value: null, text: null };
          this.unitName = { value: null, text: null };
          break;
        case appStrings.VALUESETTYPE.LEASE_UNIT_LIST:
          this.unitName = { value: null, text: null };
          break;
        case appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET:
          this.selectedBillingStatus = { value: null, text: null };
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedUserName(item) {
      this.selectedCustomer.name = item.party_name;
      this.getCustomerByPartyId(item.party_id);
      this.showCommonModal = false;
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.selectedCustomer.id = response.data.data.customer_id;
            this.customer_id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customerId: this.customer_id,
        lease: {
          site_type_vset: appStrings.siteTypeVset
        }
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.selectedCustomer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    selectedSite(item) {
      this.selectedCustomer.siteName = item.site_name;
      this.selectedCustomer.siteId = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    selectedRequestNum(item) {
      this.request = {
        value: item.request_id,
        text: item.name
      };
      this.showHideCommonLeaseModal(false, null);
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    clearParams() {
      this.legalEntity = { value: null, text: null };
      this.operatingUnit = { value: null, text: null };
      this.source = { value: null, text: null };
      this.prjName = { value: null, text: null };
      this.towerName = { value: null, text: null };
      this.floorName = { value: null, text: null };
      this.unitName = { value: null, text: null };
      this.request = { value: null, text: null };
      this.currency = { name: null, code: null, id: null };
      this.selectedBillingStatus = { value: null, text: null };
      this.billNo = null;
      this.dueDate = [];
      this.glDate = [];
      this.selectedCustomer = {
        name: null,
        id: null,
        siteName: null,
        siteId: null
      };
      this.start_date_end_date = [];
      this.amtFrom = null;
      this.amtTo = null;
    }
  },
  destroyed() {
    this.eventBus.$off('getCurrency');
  }
};
