import CodeCombination from '@/app/components/shared/codeCombination';
import Party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import Currency from '../../../../../admin/currency';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'TenantManualBillDetails',
  props: ['billHdrId'],
  components: {
    CodeCombination,
    DatePicker,
    Currency,
    Party
  },
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      manualBillHdrData: {
        bill_hdr_id: null,
        bill_no: null,
        trx_type_dtl_id: 8,
        trx_type: 'INVOICE',
        le_id: null,
        le_name: null,
        ou_id: null,
        ou_name: null,
        lease_prj_id: null,
        lease_prj_name: null,
        lease_prj_code: null,
        lease_tower_name: null,
        lease_floor_name: null,
        lease_prj_unit_id: null,
        lease_prj_unit_name: null,
        customer_id: null,
        customer_name: null,
        customer_site_id: null,
        customer_site: null,
        customer_site_address: null,
        billing_period_start_date: null,
        billing_period_end_date: null,
        value_date: null,
        bill_status_vset: 'U',
        bill_status_vset_meaning: 'UNPROCESSED',
        total_amount: null,
        currency_id: 68,
        currency: 'INR',
        payment_term_id: null,
        payment_term_name: null,
        source_hdr_id: null,
        source_name: null,
        due_date: null,
        gl_date: null,
        bill_date: null,
        first_party_gstn: null,
        third_party_gstn: null,
        address: null,
        debtor_ccid: null,
        debtor_acc: null,
        debtor_account_meaning: null,
        receipt_methods_name: null,
        receipt_methods_id: null
      },
      manualBillLineData: [
        {
          bill_comp_id: 0,
          description: null,
          area: null,
          bill_uom: null,
          bill_uom_meaning: null,
          comp_type_vset: null,
          comp_type_vset_meaning: null,
          gl_date: null,
          value_date: null,
          rate: null,
          amount: null,
          hsn_sac_code: null,
          tax_amount: null,
          tax_cat_id: null,
          tax_cat_name: null,
          revenue_ccid: null,
          revenue_acc: null,
          revenue_account_meaning: null
        }
      ],
      manualBillLineFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'comp_type_vset',
          label: 'Comp Type'
        },
        {
          key: 'description'
        },
        {
          key: 'area'
        },
        {
          key: 'bill_uom',
          label: 'Area UOM'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'value_date'
        },
        {
          key: 'rate'
        },
        {
          key: 'amount'
        },
        {
          key: 'hsn_sac_code',
          label: 'SAC/HSN'
        },
        {
          key: 'revenue_acc'
        },
        {
          key: 'tax_cat_name'
        },
        {
          key: 'tax_amount'
        }
      ],
      vsetCode: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      depVsetParam: null,
      openCurrencyModal: false,
      showPartyModal: false,
      showCustomerSiteModal: false,
      gridIndex: 0,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        }
      ],
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      }
    };
  },
  mounted() {
    if (this.billHdrId) {
      this.getTenantManualBillDetails(this.billHdrId);
    } else {
      this.editMode = true;
      this.eventBus.$on('getCurrency', getCurrency => {
        this.manualBillHdrData.currency = getCurrency.currency_code;
        this.manualBillHdrData.currency_id = getCurrency.currency_id;
        this.openCurrencyModal = false;
      });
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.addEditTenantManualBill();
        }
        if (actionName === 'delete') {
          if (this.manualBillHdrData.bill_status_vset == 'P') {
            alert("Processed record can't be deleted.");
          } else {
            const toDelete = confirm(
              'Are you sure, you want to delete this record?'
            );
            if (toDelete) {
              this.deleteTenantBill();
            }
          }
        }
      }
    });
  },
  methods: {
    deleteTenantBill() {
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/deleteTenantBill', this.billHdrId)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.$emit('onDeletion');
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getTenantManualBillDetails(billHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/getTenantManualBillDetails', billHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.manualBillHdrData = response.data.data;
            this.manualBillLineData = response.data.data.bill_components;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditTenantManualBill() {
      const notValidated =
        !this.manualBillHdrData.le_id ||
        !this.manualBillHdrData.ou_id ||
        !this.manualBillHdrData.source_hdr_id ||
        !this.manualBillHdrData.lease_prj_id ||
        !this.manualBillHdrData.customer_id ||
        !this.manualBillHdrData.customer_site_id ||
        !this.manualBillHdrData.bill_status_vset ||
        !this.manualBillHdrData.due_date ||
        !this.manualBillHdrData.gl_date ||
        !this.manualBillHdrData.value_date ||
        !this.manualBillHdrData.bill_date ||
        !this.manualBillHdrData.currency_id;
      if (!notValidated) {
        const payload = {
          bill_hdr_tmp_id: this.billHdrId ? this.billHdrId : 0,
          le_id: this.manualBillHdrData.le_id,
          ou_id: this.manualBillHdrData.ou_id,
          source_hdr_id: this.manualBillHdrData.source_hdr_id,
          trx_type_dtl_id: this.manualBillHdrData.trx_type_dtl_id,
          lease_prj_id: this.manualBillHdrData.lease_prj_id,
          lease_prj_unit_id: this.manualBillHdrData.lease_prj_unit_id,
          customer_id: this.manualBillHdrData.customer_id,
          customer_site_id: this.manualBillHdrData.customer_site_id,
          bill_status_vset: this.manualBillHdrData.bill_status_vset,
          period_start_date: this.manualBillHdrData.billing_period_start_date,
          period_end_date: this.manualBillHdrData.billing_period_end_date,
          value_date: this.manualBillHdrData.value_date,
          base_currency_id: this.manualBillHdrData.currency_id,
          transactional_currency_id: this.manualBillHdrData.currency_id,
          payment_term_id: this.manualBillHdrData.payment_term_id,
          penalty_due_date: this.manualBillHdrData.due_date,
          due_date: this.manualBillHdrData.due_date,
          gl_date: this.manualBillHdrData.gl_date,
          doc_gen_date: this.manualBillHdrData.bill_date,
          debtor_ccid: this.manualBillHdrData.debtor_ccid,
          proj_reciept_mpng_id: this.manualBillHdrData.receipt_methods_id,
          bill_components: this.manualBillLineData.map(data => {
            return {
              bill_comp_tmp_id: data.bill_comp_id ? data.bill_comp_id : 0,
              lease_com_display_name: data.description,
              amount: data.amount,
              rate: data.rate,
              area: data.area,
              area_uom: data.bill_uom,
              comp_type_vset: data.comp_type_vset,
              hsn_sac_code: data.hsn_sac_code,
              revenue_ccid: data.revenue_ccid,
              tax_category: data.tax_cat_id
            };
          })
        };
        this.loader = true;
        this.$store
          .dispatch('leaseInvoice/addEditTenantManualBill', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.getTenantManualBillDetails(response.data.data);
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please fill all mandatory fields.');
      }
    },
    openValueSetModal(vsetCode, index) {
      this.gridIndex = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.OU_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.manualBillHdrData.le_id;
        this.depVsetParam = this.manualBillHdrData.ou_id;
      } else if (vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        this.parent_value_set_id = this.manualBillHdrData.ou_id;
        this.depVsetParam = this.manualBillHdrData.le_id;
      } else if (vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.parent_value_set_id = 79;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = 1;
      } 
      else if (
        this.vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        this.parent_value_set_id = this.manualBillHdrData.lease_prj_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.manualBillHdrData.le_name = item.value_code;
        this.manualBillHdrData.le_id = item.value_set_dtl_id;
        this.manualBillHdrData.ou_id = null;
        this.manualBillHdrData.ou_name = null;
        this.manualBillHdrData.source_hdr_id = null;
        this.manualBillHdrData.source_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.manualBillHdrData.ou_name = item.value_code;
        this.manualBillHdrData.ou_id = item.value_set_dtl_id;
        this.manualBillHdrData.source_hdr_id = null;
        this.manualBillHdrData.source_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        this.manualBillHdrData.source_hdr_id = item.value_set_dtl_id;
        this.manualBillHdrData.source_name = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.manualBillHdrData.lease_prj_name = item.value_code;
        this.manualBillHdrData.lease_prj_id = item.value_set_dtl_id;
        this.manualBillHdrData.lease_prj_code = item.lease_prj_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.manualBillHdrData.lease_prj_unit_name = item.value_code;
        this.manualBillHdrData.lease_prj_unit_id = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PAYMENT_TERM) {
        this.manualBillHdrData.payment_term_name = item.value_code;
        this.manualBillHdrData.payment_term_id = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.manualBillLineData[this.gridIndex].bill_uom_meaning =
          item.value_meaning;
        this.manualBillLineData[this.gridIndex].bill_uom = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.COMP_VSET) {
        this.manualBillLineData[this.gridIndex].comp_type_vset_meaning = item.value_meaning;
        this.manualBillLineData[this.gridIndex].comp_type_vset = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.manualBillLineData[this.gridIndex].tax_cat_id =
          item.value_set_dtl_id;
        this.manualBillLineData[this.gridIndex].tax_cat_name = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET) {
        this.manualBillHdrData.receipt_methods_name = item.value_code;
        this.manualBillHdrData.receipt_methods_id = item.value_set_dtl_id;
      }
      this.manualBillLineData = JSON.parse(JSON.stringify(this.manualBillLineData));
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      this.gridIndex = index;
      switch (vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.manualBillHdrData.le_id = null;
          this.manualBillHdrData.le_name = null;
          this.manualBillHdrData.ou_id = null;
          this.manualBillHdrData.ou_name = null;
          this.manualBillHdrData.source_hdr_id = null;
          this.manualBillHdrData.source_name = null;
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          this.manualBillHdrData.ou_id = null;
          this.manualBillHdrData.ou_name = null;
          this.manualBillHdrData.source_hdr_id = null;
          this.manualBillHdrData.source_name = null;
          break;
        case appStrings.VALUESETTYPE.SOURCE_TYPE:
          this.manualBillHdrData.source_hdr_id = null;
          this.manualBillHdrData.source_name = null;
          break;
        case appStrings.VALUESETTYPE.LEASE_PROJECT_LIST:
          this.manualBillHdrData.lease_prj_id = null;
          this.manualBillHdrData.lease_prj_name = null;
          this.manualBillHdrData.lease_prj_code = null;
          break;
        case appStrings.VALUESETTYPE.LEASE_UNIT_LIST:
          this.manualBillHdrData.lease_prj_unit_name = null;
          this.manualBillHdrData.lease_prj_unit_id = null;
          break;
        case appStrings.VALUESETTYPE.FMS_PAYMENT_TERM:
          this.manualBillHdrData.payment_term_id = null;
          this.manualBillHdrData.payment_term_name = null;
          break;
        case this.manualBillHdrData.customer_name:
          this.manualBillHdrData.customer_id = null;
          this.manualBillHdrData.customer_name = null;
          this.manualBillHdrData.customer_site = null;
          this.manualBillHdrData.customer_site_id = null;
          break;
        case this.manualBillHdrData.customer_site:
          this.manualBillHdrData.customer_site = null;
          this.manualBillHdrData.customer_site_id = null;
          break;
        case appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET:
          this.manualBillHdrData.lease_prj_id = null;
          this.manualBillHdrData.receipt_methods_id = null;
          this.manualBillHdrData.receipt_methods_name = null;
          break;
        case appStrings.VALUESETTYPE.UOM:
          this.manualBillLineData[this.gridIndex].bill_uom = null;
          this.manualBillLineData[this.gridIndex].bill_uom_meaning = null;
          break;
        case appStrings.VALUESETTYPE.COMP_VSET:
          this.manualBillLineData[this.gridIndex].comp_type_vset = null;
          this.manualBillLineData[this.gridIndex].comp_type_vset_meaning = null;
          break;
        case appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET:
          this.manualBillLineData[this.gridIndex].tax_cat_id = null;
          this.manualBillLineData[this.gridIndex].tax_cat_name = null;
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    showHidePartyModal(flag) {
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
      this.showPartyModal = flag;
    },
    getSelectedPartyCustomer(item) {
      this.manualBillHdrData.customer_name = item.party_name;
      this.manualBillHdrData.customer_id = item.party_id;
      this.manualBillHdrData.customer_site = null;
      this.manualBillHdrData.customer_site_id = null;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.manualBillHdrData.customer_id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customer_id: this.manualBillHdrData.customer_id,
        ou_id: this.manualBillHdrData.ou_id
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.manualBillHdrData.customer_name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    selectedSite(item) {
      this.manualBillHdrData.customer_site = item.site_name;
      this.manualBillHdrData.customer_site_id = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.manualBillLineData.push({
          bill_comp_id: 0,
          description: null,
          area: null,
          bill_uom: null,
          bill_uom_meaning: null,
          comp_type_vset: null,
          comp_type_vset_meaning: null,
          gl_date: null,
          value_date: null,
          rate: null,
          amount: null,
          hsn_sac_code: null,
          tax_amount: null,
          tax_cat_id: null,
          tax_cat_name: null,
          revenue_ccid: null,
          revenue_acc: null,
          revenue_account_meaning: null
        });
      }
    },
    removeRow(index) {
      this.manualBillLineData.splice(index, 1);
    },
    showHideCodeCombinationModal(flag, accType, index) {
      this.gridIndex = index;
      this.accountType = accType;
      if (flag) {
        if (!this.manualBillHdrData.le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.manualBillHdrData.lease_prj_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEASEPRJMSG);
        }
        this.combinationDetails.le_id = this.manualBillHdrData.le_id;
        this.combinationDetails.project_code = this.manualBillHdrData.lease_prj_code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'debtor') {
        this.manualBillHdrData.debtor_ccid = item.ccid;
        this.manualBillHdrData.debtor_acc = item.segment_code;
        this.manualBillHdrData.debtor_account_meaning = item.segment_meaning;
      } else if (this.accountType === 'revenue') {
        this.manualBillLineData[this.gridIndex].revenue_ccid = item.ccid;
        this.manualBillLineData[this.gridIndex].revenue_acc = item.segment_code;
        this.manualBillLineData[this.gridIndex].revenue_account_meaning =
          item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('getCurrency');
  }
};
